<template>
  <div class="p-2">
    <ul class="list-tutorials">
      <li v-for="record in records" :key="record.uuid">
        {{ record?.nameMod }}
        <ul class="list-links">
          <li v-for="link in record.links" :key="link.uuid">
            <div class="d-flex align-items-center">
              <b-button
                v-if="hasPermission(module, 'edit')"
                class="mr-2 btn-action-tutorial"
                size="sm"
                @click.prevent="
                  () =>
                    $emit('editing', { ...link, moduleUuid: record.moduleUuid })
                "
              >
                <b-icon-pencil-square />
              </b-button>
              <b-button
                v-if="hasPermission(module, 'delete')"
                class="mr-2 btn-action-tutorial"
                size="sm"
                @click.prevent="() => $emit('deleting', link.uuid)"
              >
                <b-icon-trash-fill />
              </b-button>
              <router-link :to="{ name: 'watchVideo', params: { uuid: link.uuid }}">
                {{ link.name }}
              </router-link>
            </div>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
import { BButton, BIconPencilSquare, BIconTrashFill } from "bootstrap-vue";
import PermissionAction from "@/mixins/PermissionAction";
export default {
  mixins: [PermissionAction],
  components: {
    BButton,
    BIconPencilSquare,
    BIconTrashFill,
  },
  props: {
    records: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      module: "59e59519-7182-4595-830f-363123edbdd9",
    };
  },
};
</script>

<style lang="css">
.list-tutorials,
.list-links {
  list-style: none;
}

.list-tutorials li {
  font-size: 20px;
  font-weight: 500;
  margin-top: 10px;
  margin-bottom: 20px;
  border-bottom: 1.5px solid rgb(207, 192, 192);
}

.list-links li {
  border: none !important;
  margin-bottom: 15px !important;
  padding-left: 30px;
}

.list-links li a {
  font-size: 18px;
  font-weight: 600;
  color: var(--second-color);
}

.list-links li:hover {
  color: var(--second-color) !important;
  text-decoration: underline;
}

.btn-action-tutorial {
  background: transparent !important;
  color: var(--black-color) !important;
}
</style>
