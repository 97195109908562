<template>
  <b-container fluid>
    <button-back :redirect="'/tutoriales'" />
    <b-row>
      <b-col cols="12" v-if="records.length > 0">
        <h3>{{ records[0].nameMod }}</h3>
      </b-col>
    </b-row>
    <b-row class="mb-2" v-if="isBusy">
      <b-col lg="12" md="12" sm="12">
        <div class="text-center text-success my-2">
          <b-spinner class="align-middle mr-1" />
          <strong>Cargando datos...</strong>
        </div>
      </b-col>
    </b-row>
    <section v-else>
      <div v-if="records.length > 0">
        <div class="accordion" role="tablist">
          <list-tutorial
            :records="records"
          />
        </div>
      </div>
      <div v-else>
        <b-row class="mb-2">
          <b-col lg="12" md="12" sm="12">
            <div class="text-center my-2">
              <strong>No hay datos para mostrar.</strong>
            </div>
          </b-col>
        </b-row>
      </div>
    </section>
  </b-container>
</template>

<script>
import {
  BContainer,
  BRow,
  BCol,
  BSpinner,
} from "bootstrap-vue";
import ButtonBack from "@/components/buttons/ButtonBack";
import ListTutorial from "./components/ListTutorial";

export default {
  inject: ["tutorialRepository"],
  components: {
    BContainer,
    BRow,
    BCol,
    BSpinner,
    ButtonBack,
    ListTutorial,
  },
  data() {
    return {
      records: [],
      isBusy: true,
      moduleUuid: this.$route.params.uuid,
    };
  },
  methods: {
    async handleLoadTutorials() {
      const me = this;
      me.records = [];

      try {
        me.isBusy = true;

        const { data } = await me.tutorialRepository.getAllByModule(me.moduleUuid);
        data?.forEach((el) => {
          const record = me.records.find(
            (record) => record.moduleUuid == el.moduleUuid
          );

          if (record) {
            record.links.push({
              uuid: el.uuid,
              link: el.link,
              name: el.name,
            });
          } else {
            me.records.push({
              moduleUuid: el.moduleUuid,
              nameMod: el.module.name_spanish,
              links: [
                {
                  uuid: el.uuid,
                  link: el.link,
                  name: el.name,
                },
              ],
            });
          }
        });
      } catch (error) {
        me.records = [];
        const { status } = error.data;
        if (status == 419) {
          me.$bvModal.show("expired-token-model");
        } else if (status == 403) {
          me.$bvModal.show("session-finish-modal");
        }
      } finally {
        me.isBusy = false;
      }
    },
  },
  async mounted() {
    const me = this;
    me.handleLoadTutorials();
  },
};
</script>
<style lang="css">
.card-tutorial {
  background: transparent !important;
  border: none !important;
}
.btn-collapse-tutorial {
  background: transparent !important;
  color: var(--black-color) !important;
  font-size: 18px;
  border: none !important;
  justify-content: flex-start !important;
  font-weight: 600 !important;
}
</style>
