<template>
  <router-link :to="redirect" class="btn-back">
    <b-icon-arrow-left />
  </router-link>
</template>

<script lang="js">
import {
  BIconArrowLeft,
} from "bootstrap-vue";

export default {
  props: {
    redirect: {
      type: String,
      default: "/",
    },
    params: {
      type: Object,
      default: null,
    }
  },
  components: {
    BIconArrowLeft
  },
  methods: {}
}
</script>
